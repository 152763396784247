import React from 'react';
import { getFormatedEpisodesCategoryTitle } from '../../utils/watch';

export default function WatchFilterSlider({ categories, onCatSelected, selectedCat }) {
  return (
    <ul className="fil_cat-list d-flex">
      <li
        className={`fil_cat-item  mg-r-5 mg-b-5 ${selectedCat === 'all' ? 'active' : ''}`}
        onClick={() => onCatSelected('all')}
      >
        All
      </li>
      {categories &&
        categories.length > 0 &&
        categories.map(
          (categoryName, index) =>
            categoryName && (
              <li
                key={`categoryName-${index}`}
                className={`fil_cat-item  mg-r-5 mg-b-5 ${selectedCat === categoryName ? 'active' : ''}`}
                onClick={() => {
                  onCatSelected(categoryName);
                }}
              >
                {getFormatedEpisodesCategoryTitle(categoryName)}
              </li>
            )
        )}
    </ul>
  );
}
