import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Loadable from 'react-loadable';

import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import { normalizePodCastData } from '../utils/podCast';
import WatchFilterSlider from '../components/Carousel/WatchFilterSlider';
import PodCastListsWithCategories from '../components/podcast/podCastListsWithCategories';
import { isEmpty } from 'lodash';
import PodcastExternalLinks from '../components/PodcastExternalLinks/PodcastExternalLinks';
const loader = () => <></>;

const LoadableBannerCarousel = Loadable({
  loader: () => import('../components/podcast/PodCastTopSlider'),
  loading: loader,
});

const PodCast = ({ data }) => {
  const { podCastData, bgPodCast, podCastCategoriesWithPriorityOrder } = data;
  const [selectedCat, setSelectedCat] = useState('all');

  const featuredEpisodes = podCastData.nodes.slice(0, 3);

  const sortWithPriorities = (normalizedData) => {
    return podCastCategoriesWithPriorityOrder.nodes.map((item) => {
      return {
        season: item.uid,
        data: normalizedData[item.uid],
      };
    });
  };

  const normalizedPodCastData = sortWithPriorities(normalizePodCastData(podCastData.nodes));

  return (
    <BodyClassName className="body-light page-watch podcast">
      <DefaultLayoutComponent title="Listen">
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner bgImage bg-pos-center-top"
            fluid={bgPodCast.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-lg pd-x-sm la-pn top-slider">
              <div className="carousel pd-x-sm la-pn slideshow-container">
                <LoadableBannerCarousel podCast={featuredEpisodes} />
              </div>
            </div>
          </BackgroundImage>
          <section className="categories-watch categories-filters bg-dark">
            <div className="wrapper wrapper-lg pd-x-md ">
              <div className="filter-categories pd-x-sm">
                <WatchFilterSlider
                  categories={normalizedPodCastData.map((item) => item.data && item.season)}
                  onCatSelected={setSelectedCat}
                  selectedCat={selectedCat}
                />
              </div>

              <div className="cat_watch-list">
                {!isEmpty(normalizedPodCastData) &&
                  normalizedPodCastData
                    .filter((elm) => {
                      if (selectedCat !== 'all') {
                        return selectedCat === elm.season;
                      }

                      return true;
                    })
                    .map((podCastSeason, index) => {
                      return (
                        podCastSeason.data && (
                          <PodCastListsWithCategories
                            key={`${index}-${podCastSeason}`}
                            title={podCastSeason.season}
                            data={podCastSeason.data}
                            showCategory={true}
                          />
                        )
                      );
                    })}
              </div>
            </div>
          </section>
          <div>
            <PodcastExternalLinks />
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default PodCast;

export const query = graphql`
  query PodCastPage {
    bgPodCast: file(relativePath: { eq: "podCastBg.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    podCastData: allPrismicPodcast(sort: { fields: data___priority, order: ASC }) {
      nodes {
        data {
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          season {
            type
            uid
          }
          podcastDuration: podcast_duration
          priority
        }
        uid
      }
    }
    podCastCategoriesWithPriorityOrder: allPrismicAudioCategory(sort: { order: ASC, fields: data___priority }) {
      nodes {
        uid
        data {
          priority
        }
      }
    }
  }
`;
