import React from 'react';

import PodCastCard from './podcast-card';

export default function PodCastListsWithCategories({ title, data, showCategory }) {
  return (
    !!data.length && (
      <div className="series" style={{ display: showCategory ? 'inherit' : 'none' }}>
        <div className="cat_watch-body ">
          <div className={'cat_watch-head cat-title pd-x-sm'}>
            {title && (
              <>
                <span className="watch_season-title">{title.replace(/-/g, ' ')}</span>
              </>
            )}
          </div>
          <div>
            <div className="wrapper wrapper-lg">
              <div className="cat_watch-list">
                <div className="cat_watch-row">
                  <div className="cat_watch-body-list">
                    <div className="cat_watch-item">
                      <div className="watch_item-row columns-4 d-flex">
                        {!!data?.length &&
                          data
                            .slice(0, 12)
                            .map((podCast, index) => !!podCast && <PodCastCard key={index} {...podCast} />)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
