export const normalizePodCastData = (podCastData) => {
  return podCastData.reduce((prev, current) => {
    const season = current.data.season.uid;
    return { ...prev, [season]: !!prev[season] ? [...prev[season], { ...current }] : [{ ...current }] };
  }, {});
};

/**
 * @param  {} firstContent
 * @param  {} secondContent
 *
 *returns sliceNumber according to content
 */
export const getSliceNumber = (firstContent, secondContent, thirdContent, currentLists) => {
  if (
    (firstContent === 0 && thirdContent === 0 && secondContent > 0) ||
    (secondContent === 0 && thirdContent === 0 && firstContent > 0) ||
    (secondContent === 0 && firstContent === 0 && thirdContent > 0)
  ) {
    if (currentLists.length < 2) return 2;
    return 3;
  }
  if (
    ((firstContent === 0 || thirdContent === 0) && secondContent > 1) ||
    ((secondContent === 0 || thirdContent === 0) && firstContent > 1) ||
    ((secondContent === 0 || firstContent === 0) && thirdContent > 1)
  ) {
    if (currentLists.length < 2) return 2;
    return 1;
  }
  if (firstContent >= 1 && secondContent >= 1 && thirdContent >= 1) {
    return 1;
  }
  return 4;
};
